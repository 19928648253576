<template>
  <base-layout>
    <ion-content class="has-header">
      <div style="width:100%;" v-if="room">
        <img id="img_1" :src="room.image" usemap="#map_1" border="0" width="100%" alt=""
             style="width:inherit;height:inherit"/>
        <map id="map_1" name="map_1" v-if="areas" style="width:inherit;height:inherit">
          <area v-for="item in areas" :key="item.id" :shape="item.shape" :coords="item.calculatedCoords"
                :href="item.target" :title="item.title">
        </map>
        <!-- additional content goes here, maybe add dynamic banner to this area, could be a directive also-->
        <div v-for="container in containers" :key="container.id" :style="calcPosition(container)">
          <div v-if="isValid">
            <router-link :to="container.actions[isValid].link"
                         :style="{'display':'block','width':container.dimension.width + 'px !important', 'height':container.dimension.height + 'px !important'}"></router-link>
          </div>
          <div v-if="!isValid" @click="showMessage(container.actions[isValid].click)"
               :style="{'display':'block','width':container.dimension.width + 'px !important', 'height':container.dimension.height + 'px !important'}">
          </div>
        </div>

      </div>
    </ion-content>
  </base-layout>
</template>
<script>
import {IonContent} from "@ionic/vue";
import {defineComponent} from "vue";
import {mapActions} from "vuex";
import moment from "moment";

const roomlist = require('@/assets/lobbyrooms.json');

export default defineComponent({
  name: "Lobby",
  data() {
    return {
      pages: [],
      room: {},
      areas: {},
      containers: {},
      isValid: false
    };
  },
  components: {
    IonContent,
  },
  methods: {
    ...mapActions("helper", ["calculateDimension", "recalculate"]),
    calcPosition(container) {
      return {
        display: 'block',
        width: container.dimension.width + 'px',
        height: container.dimension.height + 'px',
        position: 'absolute',
        top: container.dimension.top + 'px',
        left: container.dimension.left + 'px'
      }

    },
    showMessage(msg) {
      alert(msg);
    },
    getRoom(id) {

      return roomlist.find(element => element.id == id);
    },
    async fetchRoom(id, time) {
      this.room = this.getRoom(id);
      //Vue.$set(this,'room',await this.getRoom(id))

      //let wait = time || 700;
      if (this.room) {
        console.log(this.room, time)
        //setTimeout(async function() {
        if (this.room.areas) {
          for (let i = 0; i < this.room.areas.length; i++) {
            let newCoords = await this.recalculate({
              img: '#img_' + this.room.id,
              size: this.room.size,
              coords: this.room.areas[i].coords
            });
            console.log('NEW', newCoords);
            this.room.areas[i].calculatedCoords = newCoords;
          }
          this.areas = await this.room.areas;
        }
        if (this.room.containers) {
          console.log('container', this.room.containers)
          for (let i = 0; i < this.room.containers.length; i++) {
            let containerDimension = await this.calculateDimension({
              img: '#img_' + this.room.id,
              size: this.room.size,
              coords: this.room.containers[i].coords
            });
            console.log('dimension', containerDimension);
            if (containerDimension) {
              this.room.containers[i].dimension = containerDimension;
            }
          }
          this.containers = await this.room.containers;
        }

        //}, wait)
        this.$forceUpdate();
      }
    }
  },


  watch: {
    '$route': {
      immediate: true,
      async handler(route) {
        if (route.name == 'Lobby') {
          this.moment = moment;
          await this.fetchRoom(this.$route.params.id);
        }
      }
    }
  }
});
</script>
<style lang="scss" scoped>
a {
  text-decoration: none
}
</style>